<div style="height: 475px; overflow: auto;" *ngIf="carrierLocationPairs.length > 0">
  <table>
    <thead style="position:sticky;top:0px;background-color:#9abfea;">
      <tr>
        <th *ngFor="let header of tableHeaders">{{ header }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pair of carrierLocationPairs; let i = index;"
          [style.background-color]="getBackgroundColor(i)">
        <td *ngFor="let header of tableHeaders">{{header != 'Location_ID'?pair[header]:null}}<button *ngIf="header === getColumnOfSubmitButton()" type="submit" (click)="onRowButtonClick($event)">   {{pair[header]}}  </button></td>
      </tr>
    </tbody>
  </table>
</div>


  