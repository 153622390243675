<form (ngSubmit)="onSubmit()">
  <h2>Start Here</h2>  
  <h2>Search Criteria</h2>
    <div *ngFor="let criterion of searchCriteria; let i = index">
      <label for="{{ 'criterion-' + i }}">{{ criterion }}</label>
      <input type="text" id="{{ 'criterion-' + i }}" name="{{ criterion }}">
      <br>
    </div>
    <hr>
    <button type="submit">Search</button>
</form>
  
