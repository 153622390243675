import { Component, Input} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
interface CarrierLocationPairNotes {
  [key: string]: string | string[]; // Dynamic properties for notes
}

interface CarrierLocationPair {
  [key:string]: string
}

@Component({
  selector: 'app-carrier-location-pair-notes',
  standalone: true,
  imports: [FormsModule,CommonModule],
  templateUrl: './carrier-location-pair-notes.component.html',
  styleUrl: './carrier-location-pair-notes.component.css',
})
export class CarrierLocationPairNotesComponent {

  carrierLocationPairNotes: CarrierLocationPairNotes | null = null;
  notesHeaders: string[] = [];
  @Input() hoveredCarrierLocationPair: CarrierLocationPair | null = null;
  @Input() submittedCarrierLocationPair: CarrierLocationPair | null = null;


  getBackgroundColor(index: number) {
    return index % 2 === 0 ? 'lightgreen' : 'lightpink';
  }

  isListContent(value: string | string[]): boolean {
    return Array.isArray(value);
  }

  formatValue(value: string | string[]) {
    if (this.isListContent(value)) {
      return `
        <ul>
          <li *ngFor="let item of value">{{ item }}</li>
        </ul>
      `;
    } else {
      return value;
    }
  }

  onConfirmNotes(eventData: any){
    console.log(eventData);
  }

  getCarrierLocationPairNotes(): CarrierLocationPairNotes {
    //This simulates fetching the notes based on some algorithm from a table stored locally in javascript or browser.
    console.log("getCarrierLocationPairNotes() is called")
    return {'General_Notes':['401K'],Policy_Rules:['Starting with 2 followed by 7 alphanumeric characters ten in total and 66 followed by 9 alpha numeric characters and followed. Starting with 2 followed by 7 alphanumeric characters ten in total and 66 followed by 9 alpha numeric characters and followed. Starting with 2 followed by 7 alphanumeric characters ten in total and 66 followed by 9 alpha numeric characters and followed.'],Fax:['Yes'],Email:['bing1@hotmail.com','bing1@hotmail.com','bing1@hotmail.com']};
  }

  getNoteHeaders(): string[] {
    //This simulates fetching the notes based on some algorithm from a table stored locally in javascript or browser.
    console.log("getNotesHeaders() is called")
    return ['Item','Details'];
  }
}

