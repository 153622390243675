import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SearchFormComponent } from './search-form/search-form.component';
import { CarrierLocationPairComponent } from './carrier-location-pair/carrier-location-pair.component';
import { CarrierLocationPairNotesComponent } from './carrier-location-pair-notes/carrier-location-pair-notes.component';
interface CarrierLocationPair {
  [key:string]: string
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [FormsModule,CommonModule,SearchFormComponent,CarrierLocationPairComponent,CarrierLocationPairNotesComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  getSearchCriteria(): string[] {
    //This simulates some API call.
    return ['Carrier', 'Product', 'Policy ID'];
  }

  carrierLocationPairs: CarrierLocationPair[] | null = null;
  hoveredCarrierLocationPair: CarrierLocationPair | null = null;
  submittedCarrierLocationPair: CarrierLocationPair | null = null;

  onCarrierLocationPairsReceived(event:any[]) {
    this.carrierLocationPairs = event; 
    console.log("event:")
    console.log(event)
    console.log("this.carrierLocationPairs:")
    console.log(this.carrierLocationPairs)
  }

  onCarrierLocationPairHovered(event:any) {
    this.hoveredCarrierLocationPair = event;
    console.log("this.hoveredCarrierLocationPair:")
    console.log(this.hoveredCarrierLocationPair)
    console.log("Event:")
    console.log(event.target.cells[1].firstChild.data)
  }

  onCarrierLocationPairSubmitted(event:any) {
    this.submittedCarrierLocationPair = event;
    console.log("this.submittedCarrierLocationPair:")
    console.log(this.submittedCarrierLocationPair)
    console.log("Event:")
    console.log(event.target.firstChild.data)
  }
}

