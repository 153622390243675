import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'search-form',
  standalone: true,
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css'],
  imports: [FormsModule,CommonModule],
})
export class SearchFormComponent {

  @Input() searchCriteria: string[] = [];
  @Output() searchSubmitted = new EventEmitter<object[]>();

  onSubmit() {
    // Simulate HTTP request by returning mock data
    const mockCarrierLocationPairs = [{'Carrier':'Nassau','Carrier_ID':'12345',Location:'New York',Location_ID:'54321'},{Carrier:'Met',Carrier_ID:'23456', Location:'Columbus',Location_ID:'23456'},{Carrier:'Progressive',Carrier_ID:'34567',Location:'Des Moines',Location_ID:'34567'},{Carrier:'Liberty',Carrier_ID:'34567',Location:'New Jersey',Location_ID:'34567'},{ Carrier:'Cambia',Carrier_ID:'45678',Location:'Portland',Location_ID:'45678'},{Carrier:'Nassau',Carrier_ID:'12345',Location:'New York',Location_ID:'54321'},{Carrier:'Met',Carrier_ID:'23456', Location:'Columbus',Location_ID:'23456'},{Carrier:'Progressive',Carrier_ID:'34567',Location:'Des Moines',Location_ID:'34567'},{Carrier:'Liberty',Carrier_ID:'34567',Location:'New Jersey',Location_ID:'34567'},{ Carrier:'Cambia',Carrier_ID:'45678',Location:'Portland',Location_ID:'45678'}];
    this.searchSubmitted.emit(mockCarrierLocationPairs);
    console.log("mockCarrierLocationPairs:")
    console.log(mockCarrierLocationPairs)
  }

}

