import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
interface CarrierLocationPair {
  [key:string]: string
}
interface CarrierLocationPairNotes {
  [key: string]: string | string[];
}
@Component({
  selector: 'carrier-location-pair',
  standalone: true,
  imports: [FormsModule,CommonModule],
  templateUrl: './carrier-location-pair.component.html',
  styleUrl: './carrier-location-pair.component.css',
})
export class CarrierLocationPairComponent{

  @Input() carrierLocationPairs: CarrierLocationPair[] = [];
  @Output() hoveredCarrierLocationPair = new EventEmitter<any>();
  @Output() submittedCarrierLocationPair = new EventEmitter<any>();
  columnOfSubmitButton:string = 'Location_ID';

  get tableHeaders() {
    return Object.keys(this.carrierLocationPairs[0] || {}); // Extract headers from first object
  }

  getColumnOfSubmitButton(): string{
    return this.columnOfSubmitButton;
  }

  getBackgroundColor(index: number) {
    return index % 2 === 0 ? 'white' : 'lightgray';
  }

  onRowHover(event: any) {
    this.hoveredCarrierLocationPair.emit(event);
  }

  onRowButtonClick(event: any) {
    this.submittedCarrierLocationPair.emit(event);
  }


}






