<div class="app-container">
  <div class="search-form-container">
    <search-form [searchCriteria]="getSearchCriteria()" (searchSubmitted)="onCarrierLocationPairsReceived($event)"></search-form>
  </div>
  <div class="carrier-location-pair-container" *ngIf="carrierLocationPairs">
    <carrier-location-pair [carrierLocationPairs]="carrierLocationPairs"
                          (hoveredCarrierLocationPair)="onCarrierLocationPairHovered($event)"
                          (submittedCarrierLocationPair)="onCarrierLocationPairSubmitted($event)">
    </carrier-location-pair>
  </div>
  <div class="carrier-location-pair-notes-container" *ngIf="hoveredCarrierLocationPair || submittedCarrierLocationPair">
    <app-carrier-location-pair-notes [hoveredCarrierLocationPair]="hoveredCarrierLocationPair" [submittedCarrierLocationPair]="submittedCarrierLocationPair"></app-carrier-location-pair-notes>
  </div>
</div>





