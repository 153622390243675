<div class="NotesDiv" style="height: 430px; overflow: auto;" *ngIf="getCarrierLocationPairNotes() && getNoteHeaders().length > 0">
    <table>
      <thead style="position:sticky;top:0px;background-color:#9abfea;">
        <tr>
          <th *ngFor="let header of getNoteHeaders()">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let note of getCarrierLocationPairNotes() | keyvalue; let i = index"
        [style.background-color]="getBackgroundColor(i)">
          <td>
            {{note.key}}
          </td>
          <td>
            <ul>
              <li *ngFor="let item of note.value | keyvalue; let i = index">{{ item.value }}</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="ConfirmButtonDiv">    
    <button class="ConfirmButton" (click)="onConfirmNotes($event)">Confirm Notes</button>
  <div>
  <div *ngIf="!(getCarrierLocationPairNotes() && getNoteHeaders().length > 0)">No notes available.</div>
  
